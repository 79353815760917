//@flow
import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero, { modelHomepageHero } from '../components/organisms/hero'
import SliceZone, {
  type SliceZoneData,
} from '../components/containers/sliceZone'
import { type PageMeta } from '../components/containers/seoData'
//TODO : import PrimaryButton from '../components/atoms/buttons/primary'

import {
  type RichTextAry,
  type SharpImg,
  type PrismicImg,
} from '../types/flow/gatsby-source-prismic-graphql'

export type HomepageData = {
  prismic: {
    homepage: {|
      main_heading: RichTextAry,
      summary: RichTextAry,
      hero_image: PrismicImg,
      hero_imageSharp: SharpImg,
      theme: { _meta: { uid: string } },
      body: SliceZoneData,
      seo_data: PageMeta[],
    |},
  },
}

const IndexPage = ({ data }: { data: HomepageData }): React.Node => {
  const doc = data.prismic.homepage
  const { seo_data } = doc
  if (doc) {
    const heroData = modelHomepageHero(doc)
    return (
      <Layout seoData={seo_data.pop()} path="">
        <Hero data={heroData} pt={[11]} pb={[10]} />
        <SliceZone>{doc.body}</SliceZone>
      </Layout>
    )
  }
  return null
}

export const query = graphql`
  query HomepageQuery {
    prismic {
      homepage(lang: "en-gb", uid: "index") {
        body {
          ... on PRISMIC_HomepageBodyService_grid {
            type
            fields {
              description
              service_name
              link {
                _linkType
                ... on PRISMIC_Services {
                  _meta {
                    uid
                    id
                    type
                  }
                  theme {
                    ... on PRISMIC_Theme {
                      _meta {
                        uid
                      }
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  _linkType
                }
                ... on PRISMIC__FileLink {
                  _linkType
                }
              }
              service_image
              service_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 480) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            primary {
              heading
            }
          }
          ... on PRISMIC_HomepageBodyPromotion {
            type
            primary {
              background_image
              background_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              description
              heading
              button_link {
                _linkType
                ... on PRISMIC_Services {
                  service_name
                  main_image
                  _meta {
                    uid
                    id
                    type
                  }
                }
              }
            }
          }
          ... on PRISMIC_HomepageBodyLocations {
            type
            label
            primary {
              leading_sentence
              call_to_action
            }
            fields {
              locations {
                ... on PRISMIC_Locations {
                  location
                  coordinates
                }
              }
            }
          }
          ... on PRISMIC_HomepageBodyHighlights_row {
            type
            label
            fields {
              description
              heading
            }
          }
          ... on PRISMIC_HomepageBodyCta {
            type
            label
            primary {
              testimonial {
                ... on PRISMIC_Testimonials {
                  name
                  location
                  testimonial
                }
              }
              section_title
              description
              button_text
              button_link {
                _linkType
              }
            }
          }
        }
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        main_heading
        seo_data {
          meta_description
          meta_title
          opengraph_description
          opengraph_image
          opengraph_locale
          opengraph_site_name
          opengraph_title
          twitter_card_type
          twitter_site
        }
        theme {
          ... on PRISMIC_Theme {
            _meta {
              uid
            }
          }
        }
        summary
      }
    }
  }
`
export default IndexPage
